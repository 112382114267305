<template>
<!-- 智慧化健身房指数排名 -->
    <div class="shaoxing animated bounce delay-3s">
      
        <div class="space">
            <div class="floorSpace darkBack">
                <div class="skipshao">
                    <span>体育场地总面积（平方米）</span>
                </div>
			    <p style="font-size:0.26rem;color:#08A4FD;font-weight: bold;">
					<countTo :startVal='0' :endVal='dataCon.sitearea' :duration='3000'></countTo>
				</p>
			</div>
            <div class="floorSpace darkBack" @click="skipathlete('athlete', $event)">
                <div class="skipshao">
                    <span>人均体育场地面积（平方米）</span>
                </div>
                <p style="font-size:0.26rem;color:#08A4FD;font-weight: bold;">
					<countTo :startVal='0' :endVal='dataCon.siteareap' :duration='3000' :decimals='2'></countTo>
				</p>
            </div>
			
        </div>

        <div class="space m-top-20">
            <div class="floorSpace darkBack"  @click="skipsocialinstructor('socialinstructor', $event)">
               <div class="skipshao">
                    <span>社会体育指导员人数</span>
                    <img src="../../../assets/images/jumparrow.png" alt="">
                </div>
                <p style="font-size:0.26rem;color:#08A4FD;font-weight: bold;">
					 <countTo :startVal='0' :endVal='number.people' :duration='3000'></countTo>
				</p>
			</div>
            <div class="floorSpace darkBack" @click="skipathlete('athlete', $event)">
                <div class="skipshao">
                    <span>国家体质监测合格率</span>
                </div>
                <p style="font-size:0.26rem;color:#08A4FD;font-weight: bold;">
					<countTo :startVal='0' :endVal='dataCon.constitution' :duration='3000' :decimals='1'></countTo>%
				</p>
            </div>
			
        </div>
        <div class="chart">
            <!-- <div class="chartOne darkBack" @click="skipnationalfitness('nationalfitness', $event)">
                <div class="title">
                    <div class="skipshao">
                        <span>全民健身发展指数总排名</span>
                        <img src="../../../assets/images/jumparrow.png" alt="">
                    </div>
                </div>
                <div id="histogram1" class="histogram"></div>
            </div> -->
             <div class="chartOne darkBack m-top-20">
                <div class="title">
                    <div class="skipshao2">
                        <span>体育场地面积（平方米）</span>
                        <span class="deadline">（截止{{chartDate1}}）</span>
                        <!-- <img src="../../../assets/images/jumparrow.png" alt=""> -->
                    </div>
                </div>
                <div id="histogram1" class="histogram"></div>
            </div>
            <div class="chartOne darkBack m-top-20">
                <div class="title skipshao2">
                    <span>体育彩票销售额（万元）</span>
                    <span class="deadline">（截止{{chartDate2}}）</span>
                </div>
                <div id="histogram2" class="histogram"></div>
            </div>
            <div class="chartOne darkBack">
                <div class="title skipshao2">
                    <!-- <span>青少年锦标赛即时金牌数(月)</span> -->
                    <span>青少年锦标赛即时金牌数（个）</span>
                    <span class="deadline">（截止{{chartDate3}}）</span>
                    <!-- <img src="../../../assets/images/jumparrow.png" alt=""> -->
                </div>
                <div id="histogram3" class="histogram"></div>
            </div>
            <div class="chartOne darkBack">
                <div class="title skipshao2">
                    <span>智慧化场地客流总排名（人次）</span>
                </div>
                <div id="histogram4" class="histogram"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { show } from '../../../assets/js/animate'
	// 引入数字滚动组件
import countTo from 'vue-count-to';
import {getAjax, timestamp} from "/src/assets/js/websocket";
import {mapState} from 'vuex'
import lotteryjson from "../../../../public/data/lottery.json";
import bus from '/src/assets/js/bus.js'
  export default {
    components: { countTo },
    data () {
        return {
            app: "",
            number:{
                people:0,
                ratio:0,
                // people:window.getData('主页-社会体育指导员人数',2017),
                // ratio: window.setData('主页-社会体育指导员履职率',24)
            },
            space:{
                // num1: window.setData('主页-运动员人数',6023),
                // num2: window.setData('主页-体育场地总面积（平方米）',3917703),
                num1:0,
                num2:0,
            },
            chartDate1: '',//体育场地统计面积截止日期
            chartDate2: '',//体育彩票销售额统计截止日期
            chartDate3: '',//青少年锦标赛即时金牌数
            chartOption:[
                {   
                    unit: 'm²',
                    data:["越城区",  "柯桥区", "上虞区", "诸暨市", "嵊州市",  "新昌县"],
                    number:[],
                    max:5,
                    color1: "#2D94D0",          // y轴左侧轴的颜色
                    color2: "#2D94D04C",         // 网格线的颜色
                    color3: "#2D94D0",          // 渐变色初始颜色             
                    color4: "#3AE6CE",          // 渐变色末尾颜色
                    color5: "#3AE5CE",          // 柱状图顶部的颜色
                },
                {
                    unit: '万元',
                    data:["越城区",  "柯桥区", "上虞区", "诸暨市", "嵊州市",  "新昌县"],
                    number:[],
                    max:5,
                    color1: "#E5823A",          // y轴左侧轴的颜色
                    color2: "#E9AC314C",         // 网格线的颜色
                    color3: "#E5813A",          // 渐变色初始颜色             
                    color4: "#EFDC29",          // 渐变色末尾颜色
                    color5: "#EFDB29",          // 柱状图顶部的颜色
                },
                {
                    unit: '个',
                    data:["越城区",  "柯桥区", "上虞区", "诸暨市", "嵊州市",  "新昌县"],
                    number:[],
                    max:5,
                    color1: "#159763",          // y轴左侧轴的颜色
                    color2: "#3DB56A4C",         // 网格线的颜色
                    color3: "#149663",          // 渐变色初始颜色             
                    color4: "#6BD871",          // 渐变色末尾颜色
                    color5: "#62D170",          // 柱状图顶部的颜色
                },
                {  
                    unit: '人次',
                    data:["越城区",  "柯桥区", "上虞区", "诸暨市", "嵊州市",  "新昌县"],
                    number:[],
                    max:5,
                    color1: "#850D86",          // y轴左侧轴的颜色
                    color2: "#850D864C",         // 网格线的颜色
                    color3: "#850D86",          // 渐变色初始颜色             
                    color4: "#EE88EF",          // 渐变色末尾颜色
                    color5: "#EE88EF",          // 柱状图顶部的颜色
                }
            ],
            leftTopTimer:""
        }
    },
    computed: {
        ...mapState(['homeData', 'dataCon'])
    },
    methods: {
        skipsocialinstructor(showdata, e){
            let _this = this
            show(showdata, _this)
            $('#amap').hide();
        },

        skipnationalfitness(showdata, e){
            let _this = this
            show(showdata, _this)
            $('#amap').hide();
        },
        skipoutdoorpain(showdata, e){
            let _this = this
            show(showdata, _this)
            $('#amap').hide();
        },
        fitness(id, number){
            let that = this;
            // let canye = this.$echarts.init(document.getElementById(id));

            let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById(id));
            }

            var data = number.number
            // var data = [100, 400, 200, 460, 210, 100];
            // for (let i = 0; i < 6; ++i) {
            //     data.push(Math.round(Math.random() * 200));
            // }
             
            let option = {
                 xAxis: {
                    // max: 'dataMax',
                    show:true,
                    type: 'value',
                    "axisLine":{     //x轴坐标轴
                        "show":false,
                    },
                    axisLabel:{
                        show: false,
                    },
                    
                    axisTick: {		//x轴刻度线
                        show:false
                    },
                    splitNumber: 4,
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: number.color2,
                            width: 1,
                            type: 'solid',
                        }
                　　}
                },
                grid: {         // 间距
                    left: '8%',  
                    right: '6%', 
                    top:'6%', 
                    bottom: '0%',  
                    containLabel: true,
                },
                yAxis: {
                    type: 'category',
                    data: number.data,
                    inverse: true,              // 上下反向
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                    max: number.max,          // y轴显示显示最大的条数 
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: number.color1,
                        }
                    },
                    splitLine:{show: false},
                    axisTick: {                 // y轴刻度线
                        show:false,
                        alignWithLabel: true,
                    },
                    splitArea : {show : false}, 
                    axisLabel: {               // y轴字体的颜色
                        // margin: 42,//刻度标签与轴线之间的距离
                        interval:0,//横轴信息全部显示 
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: "#FFFFFF",
                            fontSize: '0.12rem',
                            // align: 'left'
                        },                           
                    },
                },
                series: [{
                    // realtimeSort: true,
                    // name: 'X',
                    type: 'bar',
                    barWidth : '30%',              // 柱子的宽度
                    data: data,
                    animation: true,
                    barMinHeight:5,
                    animationDuration: 500,
                    itemStyle: {
                        normal: {
                            color: this.$echarts.graphic.LinearGradient(0, 0 , 1, 0, [{
                                offset: 0,
                                color: number.color3 // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: number.color4 // 100% 处的颜色
                            }], false)
                        }
                    },
                    label: {
                        formatter: "{c}",
                        show: true,
                        position: ['50%', '-140%'],
                        // position: 'top',
                        valueAnimation: true,
                        color: number.color5,    // 柱状图上字体的颜色
                        fontSize:'0.13rem',
                    },
                }],
                legend: {
                    show: true
                },
                animationDuration: 100,
                animationDurationUpdate: 3000,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear'
            };

            // function run () {
            //     var data = option.series[0].data;
            //     for (var i = 0; i < data.length; ++i) {
            //         if (Math.random() > 0.9) {
            //             data[i] += Math.round(Math.random() * 3000);
			// 			option.series[0].itemStyle.normal.color = that.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
			// 															offset: 0,
			// 															color: number.color3 // 0% 处的颜色
			// 														}, {
			// 															offset: 1,
			// 															color: number.color4 // 100% 处的颜色
			// 														}], false)
            //         }
            //         else {
            //             data[i] += Math.round(Math.random() * 3000);
			// 			option.series[0].itemStyle.normal.color = that.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
			// 															offset: 0,
			// 															color: number.color3 // 0% 处的颜色
			// 														}, {
			// 															offset: 1,
			// 															color: number.color4 // 100% 处的颜色
			// 														}], false)
            //         }
            //     }
            //     canye.setOption(option);
            // }
          //在我们的echarts方法外边直接套上setTimeout 然后设置上延时时间就可以解决了
          //setTimeout(function(){
            canye.setOption(option);
          //},200)
            //图表自动缩放
                window.addEventListener("resize",function(){
                    canye.resize();
                });
            // setTimeout(function() {
                // run();
            // }, 0);
            // setInterval(function () {
            //     run();
            // }, 3000);
        },
        // 获取左上角数据
        getLeftTop(){
            let that = this;
            var data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            getAjax({
                url: "/map/screen/getHome",
                method:"GET",
                data:data,
                success:function(res){
                    // console.log(res)
                    // 左上角数据
                    that.number.people = res.data.resumption_num;
                    that.number.ratio = res.data.resumption_lv;
                    that.space.num1 = res.data.area_total;
                    that.space.num2 = res.data.sportsman_main_sum;
                    that.$store.commit('homeData', res.data);
                    // 健康发展指数
                    // that.chartOption[0].data = [];
                    // that.chartOption[0].number = [];
                    // res.data.area_list.forEach((item,index)=>{
                    //     that.chartOption[0].data.push(item.name);
                    //     that.chartOption[0].number.push(item.full_name_fitness_development_index_num);
                    // })
                    // that.fitness("histogram1", that.chartOption[0])

                },
            })
        },
       
        
        // 获取体育场地面积
        getSitearea(){
            let that = this;
            let url = window.appHost+`api/v1/screen/Sitearea/area`
            let result = window.ajaxRequest(url)
            // that.chartOption[0].data = [];
            that.chartOption[0].number = [];
            
            
            that.chartOption[0].data.forEach((item, index) => {
                for(let i in result.data.data){
                    if (i == item) {
                        that.chartOption[0].number[index] = result.data.data[i];
                    }       
                }
            })
                
            
            //  for(let i in result.data.data){
            //     that.chartOption[0].data.push(i);
            //     that.chartOption[0].number.push(result.data.data[i]);
            // }
            that.chartDate1 = result.data.deadline;
            that.fitness("histogram1", that.chartOption[0])
            // lotteryjson.home.data.siteArea = result.data.data;
            // this.plug.upDataMap(lotteryjson.home);
            this.$store.commit('siteArea', result.data.data);
        },
        // 获取彩票销售额
        getLotterySale(){
            let that = this;
            let url = window.appHost+`api/v1/screen/Lottery/sale`
            let result = window.ajaxRequest(url)
            // console.log(result);
            // that.chartOption[1].data = [];
            console.log(that.chartOption[1].data)
            that.chartOption[1].number = [];
            that.chartOption[1].data.forEach((item, index) => {
                for(let i in result.data.data){
                    if (i == item) {
                        that.chartOption[1].number[index] = result.data.data[i];
                    }       
                }
            })
            // for(let i in result.data.data){
            //     that.chartOption[1].data.push(i);
            //     that.chartOption[1].number.push(result.data.data[i]);
            // }
            that.chartDate2 = result.data.deadline;
            that.fitness("histogram2", that.chartOption[1])
        },
        // 获取健身房运动时长排名
        getAllTime(){
            let that = this;
            let url = window.appHost+`/api/v1/screen/Venue/durationRanking`
            let result = window.ajaxRequest(url)
            // console.log(result);
            that.chartOption[1].data = [];
            that.chartOption[1].number = [];
            for(let i in result.data){
                that.chartOption[1].data.push(i);
                that.chartOption[1].number.push(result.data[i]);
            }
            that.fitness("histogram2", that.chartOption[1])
        },
        // 获取云健身时长排名
        getCloudTime(){
            let that = this;
            let url = window.appHost+`/api/v1/screen/Area/areaDuration`
            let result = window.ajaxRequest(url)
            // console.log(result);
            that.chartOption[3].data = [];
            that.chartOption[3].number = [];
            for(let i in result.data){
                that.chartOption[3].data.push(i);
                that.chartOption[3].number.push(result.data[i]);
            }
            that.fitness("histogram4", that.chartOption[3])
        },
         // 获取智慧化场地客流排名
         getAreaFlow(){
            let that = this;
            let url = window.appHost+`api/v1/screen/fitness_index/areaFlow`
            let result = window.ajaxRequest(url)
            console.log(result);
            // that.chartOption[3].data = [];
            that.chartOption[3].number = [];
            that.chartOption[3].data.forEach((item, index) => {
                result.data.area_list.forEach(i => {
                    if (i.name == item) {
                        that.chartOption[3].number[index] = i.full_name_fitness_development_index_num//result.data.data[i];
                    }  
                })
            })
            // result.data.area_list.forEach((item,index)=>{
            //     that.chartOption[3].data.push(item.name);
            //     that.chartOption[3].number.push(item.full_name_fitness_development_index_num);
            // })
            that.fitness("histogram4", that.chartOption[3])
        },
        // 青少年锦标赛金牌榜
        getTrophy(){
            let that = this;
            let url = window.appHost+`api/v1/screen/Sitearea/trophy`
            let result = window.ajaxRequest(url)
            // console.log(result);
            // that.chartOption[2].data = [];
            that.chartOption[2].number = [];
            that.chartOption[2].data.forEach((item, index) => {
                for(let i in result.data.data){
                    if (i == item) {
                        that.chartOption[2].number[index] = result.data.data[i];
                    }       
                }
            })
            // for(let i in result.data.data){
            //     that.chartOption[2].data.push(i);
            //     that.chartOption[2].number.push(result.data.data[i]);
            // }
            that.chartDate3 = result.data.deadline
            that.fitness("histogram3", that.chartOption[2])
        },

    },
    mounted () {
        let that = this
		window.messageList = this;


        // this.chartOption[0] = window.getData("主页-全民健身发展指数总排名")
        // this.chartOption[1] = window.getData("主页-健身房运动时长总排名（时）")
        // this.chartOption[2] = window.getData("主页-户外运动总排名（公里）")
        // this.chartOption[3] = window.getData("主页-云健身运动时长总排名（时）")

        // this.fitness("histogram1", this.chartOption[0])
        // this.fitness("histogram2", this.chartOption[1])
        // this.fitness("histogram3", this.chartOption[2])
        // this.fitness("histogram4", this.chartOption[3])

        
        that.leftTopTimer = setInterval(() => {
            that.getLeftTop()
            // that.getAllTime();
            // that.getCloudTime();
            that.getTrophy();
            that.getSitearea();
            that.getLotterySale();
            that.getAreaFlow();
        }, 300000);
        that.getLeftTop();
        // that.getAllTime();
        // that.getCloudTime();
        that.getTrophy();
        that.getLotterySale();
        that.getAreaFlow();
        that.getSitearea()
    },
    beforeDestroy () {
        clearInterval(this.leftTopTimer)
    }
}
</script>

<style scoped>
/* 第一行 */
.shaoxing{
    position: relative;
}
.baitop{
    width:5.06rem;
    /* height: 1.19rem; */
    height: 0.8rem;
    text-align: center;
    color:#ffffff;
    /* padding: 0.2rem; */
    padding: 0.07rem;
    display: flex;
    justify-content: space-between;
    margin-top: 0.12rem;
    margin-bottom: 0.12rem;
}

.m-top-20 {
   margin-top: 0.2rem; 
}

p{
    margin:0;
    padding:0;
}
.baitop>div{
    width:50%;
    margin-top:0.12rem;
    position: relative;
}

/* 第二行 */
.space,.chart{
    width:5.06rem;
    /* height:1.19rem; */
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-wrap:wrap;
}

.skipshao>img{
    width:0.2rem;
    height:0.2rem;
    vertical-align:middle;
}
.skipshao>span{
    font-size: 0.14rem;
}

.deadline {
    display: block;
    font-size: 0.1rem !important;
}

    .skipshao2{
        width:100%;
        display: flex;
        flex-direction: column;
    }

@media screen and (max-width: 1000px) {
    .skipshao{
        width:120%;
        display: flex;
        justify-content:center;
        margin-left:-0.2rem;
    }
    .skipshao>span{
        display: block;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
    }
    .skipshao>img{
        vertical-align:middle;
        margin-left:-0.1rem;
        margin-top:0.08rem;
        /*-webkit-transform: scale(0.8);*/
    }
}

.floorSpace{
    width:2.43rem;
    height:0.8rem;
    font-size: 0.14rem;
    color:#FFFFFF;
    padding-top:0.14rem;
}

/* 第三行 图表 */
.chartOne{
    width:2.43rem;
    height:2.4rem;
    padding: .2rem;
    margin-bottom:0.2rem;
    color:#FFFFFF;
    font-size: 0.18rem;
}
.chartOne .title{
    height:0.30rem;
    text-align: left;
}
.title{
    font-size: 0.12rem;
}

#histogram1,#histogram2,#histogram3,#histogram4{
    width:2.4rem;
    height:1.8rem;
    margin-left:-0.20rem;
}
 .number{
    font-family: unidreamLED;
		font-size: 0.26rem;
    }
  .to__led-number--no {
    background: unset;
    border: none;
    }
</style> 